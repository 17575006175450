import React, { useGlobal } from 'reactn'
import { IntlProvider } from 'react-intl'

import messagesEs from '../translations/es.json'
import messagesEn from '../translations/en.json'

const messages = {
  es: messagesEs,
  en: messagesEn
}

const LanguageProvider = ({ children }) => {
  const [language] = useGlobal('language')

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      {children}
    </IntlProvider>
  )
}

export default LanguageProvider
