import React, { setGlobal } from 'reactn'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { render } from 'react-dom'
import LanguageProvider from './components/LanguageProvider'

import { Home } from './screens/Home'

// initialize global state
setGlobal({
  language: 'es'
})

// render app
render(
  <LanguageProvider>
    <Router>
      <Switch>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Router>
  </LanguageProvider>,
  document.querySelector('#app')
)
