import React from 'reactn'
import logoBlack from './../../assets/m_black.png'
import Instagram from './../../assets/instagram.svg'
import Github from './../../assets/github.svg'
import Linkedin from './../../assets/linkedin.svg'

export const Layout = ({ children }) => {
  return (
    <div className='layout'>
      <div className='container'>
        <nav className='navbar'>
          <img src={logoBlack} className='main-logo' alt='logo' />
          <div className='social-links'>
            <Instagram className='social-icon social-icon--instagram' />
            <Github className='social-icon social-icon--github' />
            <Linkedin className='social-icon social-icon--linkedin' />
          </div>
        </nav>
        {children}
      </div>
      <footer className='footer'>
        <div className='contact'>
          <div className='container contact--container'>
            <div className='contact-info'>
              <h2>Let's talk about interesting things</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed placeat omnis quisquam accusamus dolorum laudantium voluptates pariatur illum ullam iste, sint itaque, provident nobis. Eligendi culpa earum perspiciatis rerum ad!</p>
            </div>
            <div className='contact-link'>
              mpachecopal@gmail.com
            </div>
            <div className='contact-image' />
          </div>
        </div>
        <div className='container footer--bottom'>
          <img src={logoBlack} className='main-logo' alt='logo' />
        </div>
      </footer>
    </div>
  )
}
