import React from 'reactn'
import { FormattedMessage } from 'react-intl'
import { Layout } from '../../components/Layout'
import vulcanIcon from '../../../assets/vulcan.png'

export const Home = () => {
  return (
    <Layout>
      <header className='header'>
        <div className='main-title'>
          <span>Hi there, <img src={vulcanIcon} className='vulcan-icon' alt='vulcan icon' /> I'm Manu.</span>
          <span>Frontend <b>Developer</b>,</span>
          <span>based in Barcelona.</span>
        </div>
        <div className='main-info'>
          <div className='main-image' />
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor temporibus ad possimus minima quae rem quo laboriosam accusamus, voluptatibus nostrum provident voluptatem molestias eveniet repellendus non sed nesciunt ea quas!</p>
        </div>
      </header>
      <section className='the-lab'>
        <h2>The Lab.</h2>
        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nostrum, cupidita.</p>
      </section>
    </Layout>
  )
}
